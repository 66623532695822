import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'cupons',
    path: 'cupons',
    redirect: { name: 'cupons-main' },
    props: {
      title: 'Cupones',
      routes: [],
    },
    meta: {
      title: 'Cupones',
    },
    children: [
      {
        meta: {
          title: 'Cupones',
        },
        name: 'cupons-main',
        path: '',
        component: () => import('../../../pages/shops/cupons/CuponsPage.vue'),
      },
      {
        meta: {
          title: 'Editar Cupon',
        },
        name: 'cupon-detail',
        path: ':id',
        component: () => import('../../../pages/shops/cupons/CreateOrModify.vue'),
        //New
        props: (route) => ({
          //isNew: false,
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
          //key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Cupon',
        },
        name: 'cupon-creation',
        path: 'cupon-creation',
        component: () => import('../../../pages/shops/cupons/CreateOrModify.vue'),
        //New
        props: (route) => ({
          ownProps: {
            isNew: true,
            shopId: route.query.shopOwnerId || null, // Pasar el shopId desde query si está presente
          },
          key: `new-cupon-${route.query.shopOwnerId}`,
        }),
      },
    ],
  },
] as RouteRecordRaw[]
