import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'warehouse-creation',
    path: 'warehouse/create/:shopId',
    component: () => import('../../../pages/shops/WareHouse/WareHouseCreation.vue'),
    meta: {
      title: 'Crear Almacén',
      vanish_state: true,
    },
    props: (route) => ({
      shopId: route.params.shopId,
    }),
  },
] as RouteRecordRaw[]
