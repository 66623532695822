import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'crm',
    path: 'crm',
    component: () => import('../../pages/crm/interactions/InteractionsPage.vue'),
    meta: {
      title: 'Interacciones',
      // requiredClaims: [Claims.ReadClients],
    },
    children: [
      {
        name: 'interaction-details',
        path: 'interactions/:id',
        component: () => import('../../pages/crm/interactions/components/InteractionDetails.vue'),
        redirect: { name: 'interaction-details' },
        props: {
          title: 'Detalles de la interacción',
          routes: [{ name: 'interaction-details', label: 'Detalles' }],
        },
        meta: {
          title: 'Detalles de la interacción',
        },
        children: [],
      },
    ],
  },
] as RouteRecordRaw[]
