import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'attributes',
    path: 'attributes',
    component: () => import('../../../components/TabsView.vue'),
    redirect: { name: 'attributes-main' },
    props: {
      title: 'Atributos',
      routes: [],
    },
    meta: {
      title: 'Atributos',
    },
    children: [
      {
        meta: {
          title: 'Atributos',
        },
        name: 'attributes-main',
        path: '',
        component: () => import('../../../pages/attributes/AttributesList.vue'),
      },
      {
        meta: {
          title: 'Editar Atributo',
        },
        name: 'attribute-details',
        path: 'edition/:id',
        component: () => import('../../../pages/attributes/CreateOrModify.vue'),
        //New
        props: (route) => ({
          isNew: false,
          elementId: route.params.id,
          key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Atributo',
        },
        name: 'attribute-creation',
        path: 'attribute-creation',
        component: () => import('../../../pages/attributes/CreateOrModify.vue'),
        //New
        props: {
          isNew: true,
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
