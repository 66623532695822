import { RouteRecordRaw } from 'vue-router'
import { Claims } from '../../utils/enumsHandler'
import { useAuth } from '../../stores/auth'

const paymentRoutes = [
  { name: 'payments-operations', label: 'Operaciones', meta: { requieredClaims: [Claims.ReadPaymentOperations] } },
  {
    name: 'payments-balance-operations',
    label: 'Operaciones de Balance',
    meta: { requieredClaims: [Claims.ReadPaymentBalanceOperations] },
  },
  { name: 'payments-merchants', label: 'Merchants', meta: { requieredClaims: [Claims.ReadMerchant] } },
  { name: 'payments-refunds', label: 'Reembolsos', meta: { requieredClaims: [Claims.ReadPaymentRefundHistory] } },
]

const merchantRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-merchant-detail',
    path: 'payments/merchant/:id',
    component: () => import('../../pages/payments/merchant/CreateOrModify.vue'),
    meta: {
      title: 'Detalles de Merchants',
      requieredClaims: [Claims.WriteMerchant],
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]

const operationRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-operation-detail',
    path: 'payments/operations/:id',
    component: () => import('../../pages/payments/operations/Details.vue'),
    meta: {
      title: 'Detalles de Pago',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]

const operationBalanceRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-balance-detail',
    path: 'payments/balance/:id',
    component: () => import('../../pages/payments/operationsBalance/Details.vue'),
    meta: {
      title: 'Detalles de Balance',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]

const refundRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-refund-detail',
    path: 'payments/refund/:id',
    component: () => import('../../pages/payments/refund/Details.vue'),
    meta: {
      title: 'Detalles de Reembolso',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]

export default [
  {
    name: 'payments',
    path: 'payments',
    component: () => import('../../components/TabsView.vue'),
    // redirect: { name: 'payments-operations' },
    props: {
      title: 'Pagos',
      routes: paymentRoutes,
    },
    redirect: () => {
      const authStore = useAuth()
      const userClaims = authStore.Claims
      const availableRoutes = paymentRoutes
      if (userClaims.includes(Claims.SuperAccess)) return { name: 'payments-operations' }
      const firstAllowed = availableRoutes.find(
        (route) => route.meta?.requieredClaims.some((claim) => userClaims.includes(claim)),
      )
      return firstAllowed ? { name: firstAllowed.name } : { name: 'login' }
    },
    children: [
      {
        name: 'payments-operations',
        path: 'operations',
        component: () => import('../../pages/payments/operations/PaymentsOperationsPage.vue'),
        meta: {
          title: 'Operaciones de Pago',
          requieredClaims: [Claims.ReadPaymentOperations],
        },
      },
      {
        name: 'payments-balance-operations',
        path: 'balance-operations',
        component: () => import('../../pages/payments/operationsBalance/PaymentsOperationsBalancePage.vue'),
        meta: {
          title: 'Operaciones de Balance',
          requieredClaims: [Claims.ReadPaymentBalanceOperations],
        },
      },
      {
        name: 'payments-merchants',
        path: 'merchants',
        component: () => import('../../pages/payments/merchant/PaymentsMerchantPage.vue'),
        meta: {
          title: 'Comerciantes',
          requieredClaims: [Claims.ReadMerchant],
        },
      },
      {
        name: 'payments-refunds',
        path: 'refunds',
        component: () => import('../../pages/payments/refund/PaymentsRefoundsPage.vue'),
        meta: {
          title: 'Reembolsos',
          requieredClaims: [Claims.ReadPaymentRefundHistory],
        },
      },
    ],
  },
  ...merchantRoutes,
  ...operationRoutes,
  ...refundRoutes,
  ...operationBalanceRoutes,
] as RouteRecordRaw[]
