import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'shops-settings',
    path: 'shops-settings',
    redirect: { name: 'shops-settings-main' },
    meta: {
      title: 'Configuraciones',
    },
    children: [
      {
        meta: {
          title: 'Config',
        },
        name: 'shops-settings-main',
        path: '',
        component: () => import('../../../pages/shops/settings/SettingsPage.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
