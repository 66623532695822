import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'dlq',
    path: 'dlq',
    redirect: { name: 'dlq-main' },
    props: {
      title: 'Broker',
      routes: [],
    },
    meta: {
      title: 'Broker',
    },
    children: [
      {
        meta: {
          title: 'Broker',
        },
        name: 'dlq-main',
        path: '',
        component: () => import('../../pages/dlq/DLQPage.vue'),
      },
      {
        meta: {
          title: 'Editar Broker',
        },
        name: 'dlq-detail',
        path: ':id',
        component: () => import('../../pages/dlq/Details.vue'),
        //New
        props: (route) => ({
          //isNew: false,
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
          //key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
    ],
  },
] as RouteRecordRaw[]
