<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="value" multiple class="side-bar mx-2 overflow-hidden">
      <div v-for="(route, index) in filteredNavigationRoutes" :key="index" class="sidebar-item">
        <VaSidebarItem
          :active="routeHasActiveChild(route)"
          :active-color="activeColor"
          :text-color="textColor(route)"
          role="button"
          class="parent-route active:scale-95 hover:saturate-150 duration-100"
          hover-opacity="0.10"
          @click="handleItemParentClick(index, route, $event)"
        >
          <VaSidebarItemContent class="flex flex-col gap-1">
            <VaIcon
              v-if="route.meta.icon"
              aria-hidden="true"
              :name="route.meta.icon"
              size="20px"
              class="m-0"
              :color="iconColor(route)"
            />
            <VaSidebarItemTitle class="flex text-[10px] justify-between items-center leading-5 font-semibold">
              {{ route.meta?.title ?? 'UNKNOWN_TITLE' }}
              <div @click.stop="toggleExpand(route.name)">
                <VaIcon
                  v-if="route.children"
                  :name="expandedRoutes[route.name] ? 'va-arrow-up' : 'va-arrow-down'"
                  size="20px"
                  class="toggle-icon"
                />
              </div>
            </VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>

        <div v-if="route.children && expandedRoutes[route.name]" class="children-routes">
          <!-- class="sidebar-item" -->
          <div v-for="(child, childIndex) in route.children" :key="childIndex">
            <VaSidebarItem
              :to="{ name: child.name }"
              :text-color="textColor(child)"
              :active="isActiveChildRoute(child)"
              class="child-route"
            >
              <VaSidebarItemContent class="flex flex-col gap-1">
                <VaIcon
                  v-if="child.meta.icon"
                  aria-hidden="true"
                  :name="child.meta.icon"
                  size="20px"
                  class="m-0"
                  :color="iconColor(child)"
                />
                <VaSidebarItemTitle class="flex text-[10px] justify-between items-center leading-5 font-semibold">
                  {{ child.meta?.title ?? 'UNKNOWN_TITLE' }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </div>
      </div>
    </VaAccordion>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import { useAuth } from '../../stores/auth'
import { Claims } from '../../utils/enumsHandler'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()
    const authStore = useAuth()
    const userClaims = authStore.Claims
    const value = ref<boolean[]>([])
    const expandedRoutes = ref<Record<string, boolean>>({})

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    // Alterna el estado de expansión de las subrutas
    const toggleExpand = (routeName: string) => {
      expandedRoutes.value[routeName] = !expandedRoutes.value[routeName]
    }

    const router = useRouter()
    const handleItemParentClick = (index: number, route: INavigationRoute, event: MouseEvent) => {
      // Verifica si el clic proviene del ícono
      if (event.target instanceof HTMLElement && event.target.closest('.toggle-icon')) {
        return
      }

      console.log('route => ', route)

      // Lógica para navegar manualmente
      if (route.name) {
        router.push({ name: route.name })
      }
    }

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }

      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () =>
      (value.value = filteredNavigationRoutes.value.map((route: INavigationRoute) => routeHasActiveChild(route)))

    // Computed para filtrar los menús según los claims
    const filteredNavigationRoutes = computed(() => {
      if (userClaims.includes(Claims.SuperAccess)) {
        // Si el usuario tiene acceso total, devolver todas las rutas
        return navigationRoutes.routes
      } else {
        return navigationRoutes.routes
          .map((menu) => {
            // Filtrar los children según sus permisos
            const filteredChildren: INavigationRoute[] =
              menu.children?.filter(
                (child) => child.meta?.requiredClaims?.some((claim) => userClaims.includes(claim)) ?? true,
              ) ?? []

            // Si la ruta principal tiene children permitidos, incluirlos
            if (filteredChildren.length > 0) {
              return {
                ...menu,
                children: filteredChildren, // Incorporar solo los children filtrados
              } as INavigationRoute
            }

            // Si no tiene children válidos, verificar la ruta principal
            if (menu.meta?.requiredClaims?.some((claim) => userClaims.includes(claim)) ?? true) {
              return {
                ...menu,
                // children: [], // No tiene children válidos, pero la ruta principal pasa
              } as INavigationRoute
            }

            // Si ni la ruta principal ni sus children tienen permisos, excluirla
            return null
          })
          .filter((menu): menu is INavigationRoute => menu !== null) // Eliminar valores nulos y asegurar el tipo
      }
    })

    // const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const sidebarWidth = '82px'
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    // watch(() => route.fullPath, setActiveExpand, { immediate: true })

    // Inicializa el estado según las rutas activas
    watch(
      () => route.fullPath,
      () => {
        setActiveExpand()
        // Marca automáticamente como expandidos los padres con rutas activas
        filteredNavigationRoutes.value.forEach((route) => {
          if (routeHasActiveChild(route)) {
            expandedRoutes.value[route.name] = true
          }
        })
      },
      { immediate: true },
    )
    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      filteredNavigationRoutes,
      expandedRoutes,
      handleItemParentClick,
      toggleExpand,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
    }
  },
})
</script>

<style lang="scss">
.side-bar {
  background: #f1f9fe;
  border-radius: 25px;
}

.children-routes {
  //margin-left: 20px;
}

.toggle-icon {
  cursor: pointer;
  //z-index: 1;
}
</style>
